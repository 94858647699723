<template>
  <div id="list-films" class="flex-container">
    <div class="film-box" v-for="(film, key) in films" v-bind:key="key">
      <router-link :to="'/film/'+film.id">
        <div class="film-img-holder">
          <img :src="''+film.Img+''" class="film-img" />
          <div class="film-img-hover">
            <h2>{{ film.Title }}</h2>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {GetFilms} from "../api/calls";

export default {
  name: "ListFilms",
  data(){
    return {
      films: [],
      col1: [],
      col2: [],
      col3: [],
      col4: [],
    }
  },
  methods:{
    // debug(){
    //   let firstFilms = [...this.films];
    //   for(let i=0;i<10;i++){
    //     let r = parseInt(Math.random()*firstFilms.length)
    //     this.films.push(firstFilms[r])
    //   }
    // }
  },
  async created() {
    this.films = await GetFilms()
    // this.debug()
  },

}
</script>

<style scoped>
  .flex-container{
    width:95%;
    min-width:1200px;
    margin:50px auto;

    line-height: 0;

    -webkit-column-count: 5;
    -webkit-column-gap:   10px;
    -moz-column-count:    5;
    -moz-column-gap:      10px;
    column-count:         5;
    column-gap:           10px;

  }
  .film-box{  /* Just in case there are inline attributes */
    width: 100%;
    height: auto;
    margin-bottom:10px;
  }
  .film-box .film-img-holder{
    position:relative;
    width:100%;
    height:100%;
    transition: all .2s ease-in-out;
  }
  .film-box .film-img-holder:hover{
    transform:scale(1.05);
    z-index:10;
  }
  .film-box .film-img-holder .film-img{
    width:100%;
  }
  .film-box .film-img-holder:hover .film-img-hover{
    display:block;
  }
  .film-box .film-img-holder .film-img-hover{
    display:none;
    position:absolute;
    background: rgba(21, 108, 165,0.25);
    top:0;
    left:0;
    right:0;
    bottom:0;
  }
  .film-box .film-img-holder .film-img-hover h2{
    background:rgba(255,255,255,1);
    position:absolute;
    left:0;
    right:0;
    bottom:50px;
    height:50px;
    line-height:50px;
    color:black;
    box-shadow:0 3px 3px rgb(0, 60, 100), 0 -3px 3px rgb(0, 60, 100);
  }
</style>