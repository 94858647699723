<template>
  <nav>
  <div id="bar">
    <div class="master-centered">
      <div class="logo"><img src="/img/LogoDisplay.png" alt="Logo"></div>
      <div class="menu">
        <router-link to="/home">
          <div class="menu-item">Home</div>
        </router-link>
        <router-link to="/find">
          <div class="menu-item">Find a movie</div>
        </router-link>
        <router-link to="/contact">
          <div class="menu-item">Contact us</div>
        </router-link>
      </div>
    </div>
  </div>
</nav>
</template>

<script>
export default {
  name: "NavBar"
}
</script>

<style scoped>
#bar{
  position:relative;
  top:25px;
  box-shadow:5px 0 5px rgba(0,0,0,0.5), 0 5px 5px rgba(0,0,0,0.5);
  background:#1CB9C7;
  width:100%;
  height:150px;

  color:white;
  margin-bottom:50px;
}
.logo img {
  height:150px;
  position:absolute;
  left:0;
}
.menu{
  right:250px;
  position:absolute;
  display:flex;
  height:150px;
  font-weight:bold;
  top:0;
}
.menu .menu-item {
  padding:72px 20px 60px 20px;
  /*border-left:1px solid rgba(0,0,0,0.1);*/
  /*border-right:1px solid rgba(0,0,0,0.1);*/
  color:inherit;
  text-decoration:none;
}
.menu .menu-item:hover {
  background:rgba(0,0,0,0.25);
  box-shadow:inset 0 0 3px black;
  cursor:pointer;
}
</style>