<template>
  <div id="video-player" class="master-centered">
    <div v-if="videotype.Name === 'PrimeVideo'" class="primevideo">
      <img src="/img/PrimeVideo.svg" alt="Prime Video" /><br />
      <p>Watch this film on PrimeVideo !</p>
      <a :href="videolink" target="_blank">{{ videolink }}</a>
    </div>
    <div v-if="videotype.Name === 'Vimeo'" class="vimeo">
      <iframe
          :src="'https://player.vimeo.com/video/'+GetVimeoID+'?h=4c497f70c7'"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
      </iframe>
      <p>You can watch this film on Vimeo : </p>
      <a :href="videolink" target="_blank">{{ videolink }}</a>
    </div>
    <div v-if="videotype.Name === 'Youtube'" class="youtube">
      <iframe
          :src="'https://www.youtube.com/embed/'+GetYoutubeID"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
      </iframe>
      <p>You can watch this film on Youtube : </p>
      <a :href="videolink" target="_blank">{{ videolink }}</a>
    </div>
    <div v-if="videotype.Name === 'Tou.tv'" class="toutv">
      <img src="/img/TouTv.svg" alt="Tou.Tv" /><br />
      <p>Watch this film on Tou.Tv !</p>
      <a :href="videolink" target="_blank">{{ videolink }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoPlayer",
  props:['videolink','videotype'],
  computed: {
    GetVimeoID: function() {
      return this.videolink.substr(18).trim().replaceAll("/","")
    },
    GetYoutubeID: function() {
      return this.videolink.substr(this.videolink.indexOf("?v=")+3)
    },
  },
}
</script>

<style scoped>
#video-player{
  padding:50px 0;
  margin:20px auto;
  border-bottom:2px solid rgba(0,0,0,0.5);
  border-top:2px solid rgba(0,0,0,0.5);
  width:60%;
}
#video-player img{
  width:30%;
  margin-bottom:20px;
}
#video-player p{
  margin-top:20px;
}
#video-player a{
  text-decoration:underline;
}
#video-player iframe{
  width:80%;
  height:400px;
}
</style>