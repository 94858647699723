import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import FilmDetails from '../views/FilmDetails.vue'
import FindAMovie from '../views/FindAMovie.vue'
import Contact from '../views/Contact.vue'

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/film/:id',
    name: 'Film',
    component: FilmDetails,
  },
  {
    path: '/find',
    name: 'Find',
    component: FindAMovie,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/',
    redirect: '/home',
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
