<template>
  <div id="search-bar" class="master-centered">
    <div class="search-box">
      <input type="text" name="search-input" placeholder="WhyFor, SeaBreeze, ..." />
      <button class="decoration">
        <i class="fas fa-search"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBar"
}
</script>

<style scoped>
  #search-bar{
    height:50px;
    padding:10px;
    width:100%;

    position:relative;
  }
  .search-box{
    min-width:300px;
    width:40%;
    height:50px;
    float:right;
    border:1px solid #3a3a3a;
    border-radius:10px;
    position:relative;
    display:flex;
  }
  .search-box > input{
    height:50px;
    border:0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    flex:1 1 80%;
    outline:none;
    padding:0 10px;
    font-size:20px;
  }
  .search-box > .decoration{
    flex:0 0 50px;
    border:0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left:1px solid #3a3a3a;
    cursor:pointer;
    background:#1CB9C7;
    color:white;
  }

</style>