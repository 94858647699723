<template>
  <NavBar></NavBar>
  <div id="app">
    <router-view/>
  </div>
  <footer>
    <div class="master-centered">
      <div class="socials"><img src="/img/LogoDisplay.png" alt="Logo">
        <div class="links"><a href="#"><i class="fab fa-instagram"></i></a><a href="#"><i class="fab fa-facebook-f"></i></a><a
            href="#"><i class="fab fa-youtube"></i></a></div>
      </div>
      <div class="copyright"><i class="far fa-copyright"></i> La Société de Sabrina</div>
    </div>
  </footer>
</template>

<script>
  import NavBar from '@/components/NavBar.vue';

  export default {
    components: {
      NavBar
    }
  }
</script>

<style>
@font-face {
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oxygen/v10/2sDfZG1Wl4Lcnbu6iQ.ttf) format('truetype');
}
* {
  padding: 0;
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 1000px;
}
#content {
  position: relative;
  top: 50px;
  margin-bottom: 60px;
}
.master-centered {
  position: relative;
  min-width: 800px;
  max-width: 1280px;
  width: auto;
  margin: 0 auto;
}
.flex {
  display: flex;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
#app{
  min-height:calc(100vh - 175px - 223px - 25px);
}

footer {
  background: #1CB9C7;
  min-height: 100px;
  display: flex;
  padding: 50px 0;
}
footer .socials {
  flex: 0 0 350px;
}
footer .socials img {
  width: 300px;
}
footer .socials .links {
  width: 250px;
  margin: 0 auto;
  list-style-type: none;
  text-align: left;
  display: flex;
}
footer .socials .links a {
  text-align: center;
  font-size: 2em;
  flex: 1;
}
footer .copyright {
  margin-top: 50px;
  font-style: italic;
  font-size: 0.9em;
}
</style>
