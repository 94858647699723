<template>
  <div id="home">
    <SearchBar></SearchBar>
    <ListFilms></ListFilms>
  </div>
</template>

<script>

import SearchBar from '@/components/SearchBar.vue'
import ListFilms from '@/components/ListFilms.vue'

export default {
  name: "Home",
  components:{
    SearchBar,
    ListFilms
  }
}
</script>

<style scoped>

</style>