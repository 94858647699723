<template>
  <div id="find">
    Find a movie !
  </div>
</template>

<script>
export default {
  name: "FindAMovie"
}
</script>

<style scoped>

</style>