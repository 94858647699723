import axios from "axios";
import Film from "../classes/Film";

const ROOT_URL = "http://kevin-chapron.fr:26812"

export async function GetFilms(){
    const response = await axios.get(ROOT_URL+"/Film")
    if(response.status !== 200){
        alert("Problem with data server.")
        return null
    }
    var films = []
    response.data.forEach((d) => films.push(new Film(d)))
    console.log("Films:",films)
    return films
}

export async function GetFullFilm(id){
    const response = await axios.get(ROOT_URL+"/FullFilm/"+id)
    if(response.status !== 200){
        alert("Problem with data server.")
        return null
    }
    return new Film(response.data)
}