<template>
  <div id="contact">
    CONTACT
  </div>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>

</style>