<template>
  <div class="film-detail" >
    <div class="film-detail-content" v-if="currentFilm">
      <div class="cover-holder">
        <div class="film-cover"
             :style="'background-image: url(\''+currentFilm.FilmDetails.Cover+'\');'"></div>
        <div class="text-over">
          <div class="main-title">
            {{ currentFilm.Title }}
          </div>
          <div class="genres">
            {{ formatFilmGenres }}
          </div>
        </div>
      </div>
      <div class="film-data master-centered">
        <div class="header">
          <i class="fas fa-bullhorn"></i>
          <p>{{currentFilm.DirectedBy }}</p>
          <p>{{currentFilm.WrittenBy }}</p>
          <i class="fas fa-pencil-alt"></i>

          <i class="fas fa-video"></i>
          <p>{{ currentFilm.FilmStyle.Name }} ({{currentFilm.FilmDetails.Runtime}})</p>
          <p>{{ currentFilm.FilmDetails.Language.FullName }}</p>
          <i class="fas fa-volume-up"></i>

          <i class="fas fa-calendar-day"></i>
          <p>{{ formatReleasedDate }}</p>
          <p>{{ currentFilm.FilmDetails.Location }}</p>
          <i class="fas fa-map-marker-alt"></i>
        </div>

        <div id="synopsis">
          <i class="fas fa-quote-left"></i>
          <p>{{ currentFilm.Synopsis }}</p>
          <i class="fas fa-quote-right"></i>
        </div>

        <div class="video">
          <VideoPlayer :videotype="currentFilm.VideoType" :videolink="currentFilm.VideoLink"></VideoPlayer>
        </div>


        <div class="details">
          <strong>Budget</strong>
          <em>{{ currentFilm.FilmDetails.Budget}} $</em>
          <strong>Ratio</strong>
          <em>{{ currentFilm.FilmDetails.Ratio}}</em>
          <strong>Camera</strong>
          <em>{{ currentFilm.FilmDetails.Camera}}</em>
          <strong>Tech</strong>
          <em>{{ currentFilm.FilmDetails.Tech}}</em>
          <strong>Color</strong>
          <em>
            <i class="fas fa-check" v-if="currentFilm.FilmDetails.Color"></i>
            <i class="fas fa-times" v-else></i>
          </em>
          <strong>Digital</strong>
          <em>
            <i class="fas fa-check" v-if="currentFilm.FilmDetails.FilmDigital"></i>
            <i class="fas fa-times" v-else></i>
          </em>

        </div>

        <div class="credit-casts">
          <div class="credit-casts-header">
            <div :class="{'credit-casts-header-title':true, 'active':castTarget==='cast'}" @click="castTarget='cast'"><h2>Cast</h2></div>
            <div :class="{'credit-casts-header-title':true, 'active':castTarget==='credits'}" @click="castTarget='credits'"><h2>Credits</h2></div>
          </div>
          <div class="credit-casts-content" id="credits" v-if="castTarget==='credits'">
            <div v-for="(creditcasts, key) in CreditsPeople" :key="key">
              <strong>{{ creditcasts.CreditCast.Title }}</strong>
              <em>{{ creditcasts.CreditCast.Name }}</em>
            </div>
          </div>
          <div class="credit-casts-content" id="casts" v-if="castTarget==='cast'">
            <div v-for="(creditcasts, key) in CastPeople" :key="key">
              <strong>{{ creditcasts.CreditCast.Title }}</strong>
              <em>{{ creditcasts.CreditCast.Name }}</em>
            </div>
          </div>
        </div>


        <div class="stills">
          <div class="border-horizontal"></div>
          <div class="stills-wrapper">
            <div v-for="(url, index) in GetStills" :key="'still-'+index" :style="{'background-image':'url('+url+')'}"></div>
          </div>
          <div class="border-horizontal"></div>
        </div>

        <div class="keywords">
          <span class="keyword" v-for="(v, index) in SplitKeywords" :key="'keyword-'+index">
            {{ v }}
            <span class="comma">,</span>
          </span>
        </div>
      </div>

    </div>
    <div v-else>
      <h1>Error 404: No Film found.</h1>
    </div>
  </div>
</template>


<script>

import {GetFullFilm} from "../api/calls";
import VideoPlayer from "../components/VideoPlayer";

export default {
  name: "FilmDetails",
  data(){
    return {
      currentFilm: null,
      castTarget: 'cast'
    }
  },
  methods:{
  },
  computed: {
    SplitKeywords: function() {
      return this.currentFilm.Keywords.split(",")
    },
    GetStills: function() {
      return this.currentFilm.Stills.split(",")
    },
    CastPeople: function() {
      return this.currentFilm.CreditCasts.filter(function(u) {
        return u.IsCast
      })
    },
    CreditsPeople: function() {
      return this.currentFilm.CreditCasts.filter(function(u) {
        return !u.IsCast
      })
    },
    formatReleasedDate: function() {
      const months = {
        0: 'January',
        1: 'February',
        2: 'March',
        3: 'April',
        4: 'May',
        5: 'June',
        6: 'July',
        7: 'August',
        8: 'September',
        9: 'October',
        10: 'November',
        11: 'December'
      }

      const d = new Date(this.currentFilm.Released)
      const year = d.getFullYear()
      const month = d.getMonth()
      return months[month]+", "+year;
    },
    formatFilmGenres: function(){
      return new Array(this.currentFilm.FilmGenres.slice(0,2).map((d) => {
        return d.FilmGenre.Name
      })).join(', ')
    }
  },
  components:{
    VideoPlayer
  },
  async created() {
    let id = parseInt(this.$route.params.id)
    if(id === -1 || isNaN(id)){
      this.$router.push("/")
      return
    }

    this.currentFilm = await GetFullFilm(id)
    this.$forceUpdate();

  }

}
</script>
<style scoped>

.film-detail > div > h1{
  font-style:italic;
  margin:100px auto 50px auto;
}

.cover-holder {
  position: relative;
  left: -5px;
  width: calc(100% + 10px);
  margin-bottom: 20px;
}
.cover-holder .film-cover {
  height: 600px;
  position: relative;
  width: 100%;
  background-size: cover;
  filter: blur(2px);
}
.cover-holder .text-over {
  position: absolute;
  top: calc(50% - 30px);
  left: 0;
  right: 0;
  font-size: 5em;
  color: white;
  text-align: center;
  font-family: 'Oxygen', sans-serif;
  text-shadow: 5px 5px 5px rgba(255, 255, 255, 0.1);
}

.cover-holder .text-over .genres{
  font-style:italic;
  font-size:0.33em;
}
.film-data .header {
  display: grid;
  grid-template-columns: 40px 1fr 1fr 40px;
  width:60%;
  margin:20px auto;
}
.film-data .header > * {
  height:30px;
}

.film-data .header i {
  font-size: 1.25em;
  margin: 5px 15px;
}
.film-data .header p{
  line-height:30px;
}

.film-data #synopsis{
  display:flex;
  font-style:italic;
  margin:30px auto;
}
.film-data #synopsis i{
  font-size:50px;
  margin:10px;
}
.film-data #synopsis p{
  margin:10px auto;
  color:rgba(0,0,0,0.6);
}
.film-data .details{
  width:50%;
  min-width:500px;
  margin:0 auto;
  display:grid;
  grid-auto-rows: 30px;
  grid-template-columns: 1fr 1fr;
}
.film-data .details strong, .film-data .details em{
  border:1px solid #bdc3c7;
  border-bottom:none;
  line-height:30px;
  height:30px;
}
.film-data .details strong:last-of-type, .film-data .details em:last-of-type{
  border-bottom:1px solid #bdc3c7;
}
.film-data .details strong{
  background:#ecf0f1;
}
.film-data .details em{
  border-left:none;
}
.credit-casts{
  margin:50px auto;
  position:relative;
}
.credit-casts .credit-casts-header{
  display:flex;
}
.credit-casts .credit-casts-header-title, .credit-casts .credit-casts-content{
  background:#ecf0f1;
  border:1px solid #bdc3c7;
  padding:10px;
}
.credit-casts .credit-casts-header-title{
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin-left:-1px;
  cursor:pointer;
  z-index:1;
}
.credit-casts .credit-casts-header-title.active{
  border-bottom:none;
}
.credit-casts .credit-casts-header-title h2{
  font-weight: normal;
  font-style:italic;
}
.credit-casts .credit-casts-header-title.active h2{
  font-weight: bold;
  font-style:normal;
}
.credit-casts .credit-casts-header-title.active{
  border-bottom:#ecf0f1;
}
.credit-casts .credit-casts-header-title:first-child{
  margin-left:0;
}
.credit-casts .credit-casts-content{
  z-index:0;
  overflow:hidden;
  display:flex;
  flex-wrap:wrap;
  margin-top:-1px;
}
.credit-casts .credit-casts-content > div{
  flex-basis:25%;
  flex-grow:1;
  flex-shrink:0;
  height:40px;
  padding:15px 0;
}
.credit-casts .credit-casts-content > div strong{
  display:block;
}
.stills .stills-wrapper {
  height:200px;
  display:flex;
  flex-wrap:nowrap;
  padding:10px 0;
  border-top:2px solid rgba(0,0,0,0.25);
  border-bottom:2px solid rgba(0,0,0,0.25);
}
.stills .stills-wrapper * {
  height:100%;
  flex:1;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center;
}
.keywords{
  margin: 50px 0;
  text-align:right;
  font-style:italic;
  color:rgba(0,0,0,0.4);
}
.keywords *:last-child .comma{
  display:none;
}
</style>